<template>
  <component :is="layout">
    <RouterView />
  </component>
  <AcceptTermsOfUsePopup v-model:open="showAcceptTermsOfUseModal" />
  <Toast />
</template>

<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterView, useRoute } from 'vue-router'

import Toast from '@/components/common/toast/Toast.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import AcceptTermsOfUsePopup from '@/modules/auth/components/AcceptTermsOfUsePopup.vue'
import { useAuthStore } from '@/modules/auth/store'

const { locale } = useI18n()
const authStore = useAuthStore()
const route = useRoute()

const showAcceptTermsOfUseModal = ref(false)

const user = computed(() => authStore.user)
const layout = computed(
  (): ReturnType<typeof defineComponent> => route.meta.layout || DefaultLayout
)

watch(
  locale,
  () => {
    document.dir = locale.value === 'ar' ? 'rtl' : 'ltr'
    document.documentElement.lang = locale.value
  },
  { immediate: true }
)

watch(
  () => user.value,
  (value) => {
    if (value && !value.is_latest_terms_of_use_accepted) {
      showAcceptTermsOfUseModal.value = true
    }
  }
)

watch(
  () => route.params.sport,
  (value) => {
    document.body.classList.remove('hockey')
    document.body.classList.remove('handball')
    switch (value) {
      case 'hockey':
        document.body.classList.add('hockey')
        break
      case 'handball':
        document.body.classList.add('handball')
        break
      default:
        document.body.classList.remove('hockey')
        document.body.classList.remove('handball')
    }
  },
  { immediate: true }
)
</script>
