/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClubReadModel } from '../models/ClubReadModel';
import type { ClubUpdateRequest } from '../models/ClubUpdateRequest';
import type { FavoredClubReadModel } from '../models/FavoredClubReadModel';
import type { LeagueModel } from '../models/LeagueModel';
import type { PaginatedClubReadModelList } from '../models/PaginatedClubReadModelList';
import type { PaginatedClubWalletModelList } from '../models/PaginatedClubWalletModelList';
import type { PaginatedFavoredClubReadModelList } from '../models/PaginatedFavoredClubReadModelList';
import type { PaginatedLeagueModelList } from '../models/PaginatedLeagueModelList';
import type { PatchedClubUpdateRequest } from '../models/PatchedClubUpdateRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ClubsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedClubReadModelList
     * @throws ApiError
     */
    public clubsList({
        limit,
        offset,
        ordering,
        search,
        sport,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * sport
         *
         * * `football` - Football
         * * `hockey` - Hockey
         * * `handball` - Handball
         */
        sport?: 'football' | 'handball' | 'hockey',
    }): CancelablePromise<PaginatedClubReadModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clubs/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
                'sport': sport,
            },
        });
    }
    /**
     * @returns FavoredClubReadModel
     * @throws ApiError
     */
    public clubsFavoredClubsCreate({
        clubPk,
    }: {
        clubPk: number,
    }): CancelablePromise<FavoredClubReadModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/clubs/{club_pk}/favored-clubs/',
            path: {
                'club_pk': clubPk,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public clubsFavoredClubsDestroy({
        clubPk,
    }: {
        clubPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/clubs/{club_pk}/favored-clubs/',
            path: {
                'club_pk': clubPk,
            },
        });
    }
    /**
     * @returns ClubReadModel
     * @throws ApiError
     */
    public clubsUpdateUpdate({
        clubPk,
        requestBody,
    }: {
        clubPk: number,
        requestBody: ClubUpdateRequest,
    }): CancelablePromise<ClubReadModel> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/clubs/{club_pk}/update/',
            path: {
                'club_pk': clubPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns ClubReadModel
     * @throws ApiError
     */
    public clubsUpdatePartialUpdate({
        clubPk,
        requestBody,
    }: {
        clubPk: number,
        requestBody?: PatchedClubUpdateRequest,
    }): CancelablePromise<ClubReadModel> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/clubs/{club_pk}/update/',
            path: {
                'club_pk': clubPk,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns PaginatedClubWalletModelList
     * @throws ApiError
     */
    public clubsWalletsList({
        clubPk,
        limit,
        offset,
    }: {
        clubPk: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
    }): CancelablePromise<PaginatedClubWalletModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clubs/{club_pk}/wallets/',
            path: {
                'club_pk': clubPk,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    public clubsWalletsDestroy({
        clubPk,
        walletPk,
    }: {
        clubPk: number,
        /**
         * A unique integer value identifying this OG Wallet User.
         */
        walletPk: number,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/clubs/{club_pk}/wallets/{wallet_pk}/',
            path: {
                'club_pk': clubPk,
                'wallet_pk': walletPk,
            },
        });
    }
    /**
     * @returns ClubReadModel
     * @throws ApiError
     */
    public clubsRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this club.
         */
        id: number,
    }): CancelablePromise<ClubReadModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clubs/{id}/',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns PaginatedFavoredClubReadModelList
     * @throws ApiError
     */
    public clubsFavoredClubsList({
        limit,
        offset,
        ordering,
        search,
        sport,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * sport
         *
         * * `football` - Football
         * * `hockey` - Hockey
         * * `handball` - Handball
         */
        sport?: 'football' | 'handball' | 'hockey',
    }): CancelablePromise<PaginatedFavoredClubReadModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clubs/favored-clubs/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
                'sport': sport,
            },
        });
    }
    /**
     * @returns PaginatedLeagueModelList
     * @throws ApiError
     */
    public clubsLeaguesList({
        country,
        limit,
        offset,
        ordering,
        search,
        sport,
    }: {
        /**
         * * `AF` - Afghanistan
         * * `AX` - Åland Islands
         * * `AL` - Albania
         * * `DZ` - Algeria
         * * `AS` - American Samoa
         * * `AD` - Andorra
         * * `AO` - Angola
         * * `AI` - Anguilla
         * * `AQ` - Antarctica
         * * `AG` - Antigua and Barbuda
         * * `AR` - Argentina
         * * `AM` - Armenia
         * * `AW` - Aruba
         * * `AU` - Australia
         * * `AT` - Austria
         * * `AZ` - Azerbaijan
         * * `BS` - Bahamas
         * * `BH` - Bahrain
         * * `BD` - Bangladesh
         * * `BB` - Barbados
         * * `BY` - Belarus
         * * `BE` - Belgium
         * * `BZ` - Belize
         * * `BJ` - Benin
         * * `BM` - Bermuda
         * * `BT` - Bhutan
         * * `BO` - Bolivia
         * * `BQ` - Bonaire, Sint Eustatius and Saba
         * * `BA` - Bosnia and Herzegovina
         * * `BW` - Botswana
         * * `BV` - Bouvet Island
         * * `BR` - Brazil
         * * `IO` - British Indian Ocean Territory
         * * `BN` - Brunei
         * * `BG` - Bulgaria
         * * `BF` - Burkina Faso
         * * `BI` - Burundi
         * * `CV` - Cabo Verde
         * * `KH` - Cambodia
         * * `CM` - Cameroon
         * * `CA` - Canada
         * * `KY` - Cayman Islands
         * * `CF` - Central African Republic
         * * `TD` - Chad
         * * `CL` - Chile
         * * `CN` - China
         * * `CX` - Christmas Island
         * * `CC` - Cocos (Keeling) Islands
         * * `CO` - Colombia
         * * `KM` - Comoros
         * * `CG` - Congo
         * * `CD` - Congo (the Democratic Republic of the)
         * * `CK` - Cook Islands
         * * `CR` - Costa Rica
         * * `CI` - Côte d'Ivoire
         * * `HR` - Croatia
         * * `CU` - Cuba
         * * `CW` - Curaçao
         * * `CY` - Cyprus
         * * `CZ` - Czechia
         * * `DK` - Denmark
         * * `DJ` - Djibouti
         * * `DM` - Dominica
         * * `DO` - Dominican Republic
         * * `EC` - Ecuador
         * * `EG` - Egypt
         * * `SV` - El Salvador
         * * `GQ` - Equatorial Guinea
         * * `ER` - Eritrea
         * * `EE` - Estonia
         * * `SZ` - Eswatini
         * * `ET` - Ethiopia
         * * `FK` - Falkland Islands (Malvinas)
         * * `FO` - Faroe Islands
         * * `FJ` - Fiji
         * * `FI` - Finland
         * * `FR` - France
         * * `GF` - French Guiana
         * * `PF` - French Polynesia
         * * `TF` - French Southern Territories
         * * `GA` - Gabon
         * * `GM` - Gambia
         * * `GE` - Georgia
         * * `DE` - Germany
         * * `GH` - Ghana
         * * `GI` - Gibraltar
         * * `GR` - Greece
         * * `GL` - Greenland
         * * `GD` - Grenada
         * * `GP` - Guadeloupe
         * * `GU` - Guam
         * * `GT` - Guatemala
         * * `GG` - Guernsey
         * * `GN` - Guinea
         * * `GW` - Guinea-Bissau
         * * `GY` - Guyana
         * * `HT` - Haiti
         * * `HM` - Heard Island and McDonald Islands
         * * `VA` - Holy See
         * * `HN` - Honduras
         * * `HK` - Hong Kong
         * * `HU` - Hungary
         * * `IS` - Iceland
         * * `IN` - India
         * * `ID` - Indonesia
         * * `IR` - Iran
         * * `IQ` - Iraq
         * * `IE` - Ireland
         * * `IM` - Isle of Man
         * * `IL` - Israel
         * * `IT` - Italy
         * * `JM` - Jamaica
         * * `JP` - Japan
         * * `JE` - Jersey
         * * `JO` - Jordan
         * * `KZ` - Kazakhstan
         * * `KE` - Kenya
         * * `KI` - Kiribati
         * * `KW` - Kuwait
         * * `KG` - Kyrgyzstan
         * * `LA` - Laos
         * * `LV` - Latvia
         * * `LB` - Lebanon
         * * `LS` - Lesotho
         * * `LR` - Liberia
         * * `LY` - Libya
         * * `LI` - Liechtenstein
         * * `LT` - Lithuania
         * * `LU` - Luxembourg
         * * `MO` - Macao
         * * `MG` - Madagascar
         * * `MW` - Malawi
         * * `MY` - Malaysia
         * * `MV` - Maldives
         * * `ML` - Mali
         * * `MT` - Malta
         * * `MH` - Marshall Islands
         * * `MQ` - Martinique
         * * `MR` - Mauritania
         * * `MU` - Mauritius
         * * `YT` - Mayotte
         * * `MX` - Mexico
         * * `FM` - Micronesia (Federated States of)
         * * `MD` - Moldova
         * * `MC` - Monaco
         * * `MN` - Mongolia
         * * `ME` - Montenegro
         * * `MS` - Montserrat
         * * `MA` - Morocco
         * * `MZ` - Mozambique
         * * `MM` - Myanmar
         * * `NA` - Namibia
         * * `NR` - Nauru
         * * `NP` - Nepal
         * * `NL` - Netherlands
         * * `NC` - New Caledonia
         * * `NZ` - New Zealand
         * * `NI` - Nicaragua
         * * `NE` - Niger
         * * `NG` - Nigeria
         * * `NU` - Niue
         * * `NF` - Norfolk Island
         * * `KP` - North Korea
         * * `MK` - North Macedonia
         * * `MP` - Northern Mariana Islands
         * * `NO` - Norway
         * * `OM` - Oman
         * * `PK` - Pakistan
         * * `PW` - Palau
         * * `PS` - Palestine, State of
         * * `PA` - Panama
         * * `PG` - Papua New Guinea
         * * `PY` - Paraguay
         * * `PE` - Peru
         * * `PH` - Philippines
         * * `PN` - Pitcairn
         * * `PL` - Poland
         * * `PT` - Portugal
         * * `PR` - Puerto Rico
         * * `QA` - Qatar
         * * `RE` - Réunion
         * * `RO` - Romania
         * * `RU` - Russia
         * * `RW` - Rwanda
         * * `BL` - Saint Barthélemy
         * * `SH` - Saint Helena, Ascension and Tristan da Cunha
         * * `KN` - Saint Kitts and Nevis
         * * `LC` - Saint Lucia
         * * `MF` - Saint Martin (French part)
         * * `PM` - Saint Pierre and Miquelon
         * * `VC` - Saint Vincent and the Grenadines
         * * `WS` - Samoa
         * * `SM` - San Marino
         * * `ST` - Sao Tome and Principe
         * * `SA` - Saudi Arabia
         * * `SN` - Senegal
         * * `RS` - Serbia
         * * `SC` - Seychelles
         * * `SL` - Sierra Leone
         * * `SG` - Singapore
         * * `SX` - Sint Maarten (Dutch part)
         * * `SK` - Slovakia
         * * `SI` - Slovenia
         * * `SB` - Solomon Islands
         * * `SO` - Somalia
         * * `ZA` - South Africa
         * * `GS` - South Georgia and the South Sandwich Islands
         * * `KR` - South Korea
         * * `SS` - South Sudan
         * * `ES` - Spain
         * * `LK` - Sri Lanka
         * * `SD` - Sudan
         * * `SR` - Suriname
         * * `SJ` - Svalbard and Jan Mayen
         * * `SE` - Sweden
         * * `CH` - Switzerland
         * * `SY` - Syria
         * * `TW` - Taiwan
         * * `TJ` - Tajikistan
         * * `TZ` - Tanzania
         * * `TH` - Thailand
         * * `TL` - Timor-Leste
         * * `TG` - Togo
         * * `TK` - Tokelau
         * * `TO` - Tonga
         * * `TT` - Trinidad and Tobago
         * * `TN` - Tunisia
         * * `TR` - Türkiye
         * * `TM` - Turkmenistan
         * * `TC` - Turks and Caicos Islands
         * * `TV` - Tuvalu
         * * `UG` - Uganda
         * * `UA` - Ukraine
         * * `AE` - United Arab Emirates
         * * `GB` - United Kingdom
         * * `UM` - United States Minor Outlying Islands
         * * `US` - United States of America
         * * `UY` - Uruguay
         * * `UZ` - Uzbekistan
         * * `VU` - Vanuatu
         * * `VE` - Venezuela
         * * `VN` - Vietnam
         * * `VG` - Virgin Islands (British)
         * * `VI` - Virgin Islands (U.S.)
         * * `WF` - Wallis and Futuna
         * * `EH` - Western Sahara
         * * `YE` - Yemen
         * * `ZM` - Zambia
         * * `ZW` - Zimbabwe
         */
        country?: 'AD' | 'AE' | 'AF' | 'AG' | 'AI' | 'AL' | 'AM' | 'AO' | 'AQ' | 'AR' | 'AS' | 'AT' | 'AU' | 'AW' | 'AX' | 'AZ' | 'BA' | 'BB' | 'BD' | 'BE' | 'BF' | 'BG' | 'BH' | 'BI' | 'BJ' | 'BL' | 'BM' | 'BN' | 'BO' | 'BQ' | 'BR' | 'BS' | 'BT' | 'BV' | 'BW' | 'BY' | 'BZ' | 'CA' | 'CC' | 'CD' | 'CF' | 'CG' | 'CH' | 'CI' | 'CK' | 'CL' | 'CM' | 'CN' | 'CO' | 'CR' | 'CU' | 'CV' | 'CW' | 'CX' | 'CY' | 'CZ' | 'DE' | 'DJ' | 'DK' | 'DM' | 'DO' | 'DZ' | 'EC' | 'EE' | 'EG' | 'EH' | 'ER' | 'ES' | 'ET' | 'FI' | 'FJ' | 'FK' | 'FM' | 'FO' | 'FR' | 'GA' | 'GB' | 'GD' | 'GE' | 'GF' | 'GG' | 'GH' | 'GI' | 'GL' | 'GM' | 'GN' | 'GP' | 'GQ' | 'GR' | 'GS' | 'GT' | 'GU' | 'GW' | 'GY' | 'HK' | 'HM' | 'HN' | 'HR' | 'HT' | 'HU' | 'ID' | 'IE' | 'IL' | 'IM' | 'IN' | 'IO' | 'IQ' | 'IR' | 'IS' | 'IT' | 'JE' | 'JM' | 'JO' | 'JP' | 'KE' | 'KG' | 'KH' | 'KI' | 'KM' | 'KN' | 'KP' | 'KR' | 'KW' | 'KY' | 'KZ' | 'LA' | 'LB' | 'LC' | 'LI' | 'LK' | 'LR' | 'LS' | 'LT' | 'LU' | 'LV' | 'LY' | 'MA' | 'MC' | 'MD' | 'ME' | 'MF' | 'MG' | 'MH' | 'MK' | 'ML' | 'MM' | 'MN' | 'MO' | 'MP' | 'MQ' | 'MR' | 'MS' | 'MT' | 'MU' | 'MV' | 'MW' | 'MX' | 'MY' | 'MZ' | 'NA' | 'NC' | 'NE' | 'NF' | 'NG' | 'NI' | 'NL' | 'NO' | 'NP' | 'NR' | 'NU' | 'NZ' | 'OM' | 'PA' | 'PE' | 'PF' | 'PG' | 'PH' | 'PK' | 'PL' | 'PM' | 'PN' | 'PR' | 'PS' | 'PT' | 'PW' | 'PY' | 'QA' | 'RE' | 'RO' | 'RS' | 'RU' | 'RW' | 'SA' | 'SB' | 'SC' | 'SD' | 'SE' | 'SG' | 'SH' | 'SI' | 'SJ' | 'SK' | 'SL' | 'SM' | 'SN' | 'SO' | 'SR' | 'SS' | 'ST' | 'SV' | 'SX' | 'SY' | 'SZ' | 'TC' | 'TD' | 'TF' | 'TG' | 'TH' | 'TJ' | 'TK' | 'TL' | 'TM' | 'TN' | 'TO' | 'TR' | 'TT' | 'TV' | 'TW' | 'TZ' | 'UA' | 'UG' | 'UM' | 'US' | 'UY' | 'UZ' | 'VA' | 'VC' | 'VE' | 'VG' | 'VI' | 'VN' | 'VU' | 'WF' | 'WS' | 'YE' | 'YT' | 'ZA' | 'ZM' | 'ZW' | null,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
        /**
         * sport
         *
         * * `football` - Football
         * * `hockey` - Hockey
         * * `handball` - Handball
         */
        sport?: 'football' | 'handball' | 'hockey',
    }): CancelablePromise<PaginatedLeagueModelList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clubs/leagues/',
            query: {
                'country': country,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
                'sport': sport,
            },
        });
    }
    /**
     * @returns LeagueModel
     * @throws ApiError
     */
    public clubsLeaguesRetrieve({
        id,
    }: {
        /**
         * A unique integer value identifying this league.
         */
        id: number,
    }): CancelablePromise<LeagueModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/clubs/leagues/{id}/',
            path: {
                'id': id,
            },
        });
    }
}
