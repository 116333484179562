<template>
  <div class="flex gap-2 items-center">
    <div @click="switchSport(SPORTS.FOOTBALL)">
      <LogoSvgCT class="logo" v-if="route.params.sport === SPORTS.FOOTBALL" />
      <LogoSvgCTInvactive class="logo" v-else />
    </div>
    <SvgIcon class="text-accent-500" name="separator-2" />
    <div @click="switchSport(SPORTS.HOCKEY)">
      <LogoSvgCH class="logo" v-if="route.params.sport === SPORTS.HOCKEY" />
      <LogoSvgCHInvactive class="logo" v-else />
    </div>
    <!--    <SvgIcon class="text-accent-500" name="separator-2" />-->
    <!--    <div @click="switchSport(SPORTS.HANDBALL)">-->
    <!--      <LogoSVGCHand class="logo" v-if="route.params.sport === SPORTS.HANDBALL" />-->
    <!--      <LogoSVGCHandInvactive class="logo" v-else />-->
    <!--    </div>-->
  </div>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'

import LogoSvgCH from '@/assets/images/logos/logo-ch.svg?component'
import LogoSvgCHInvactive from '@/assets/images/logos/logo-ch-inactive-dark.svg?component'
import LogoSvgCT from '@/assets/images/logos/logo-ct.svg?component'
import LogoSvgCTInvactive from '@/assets/images/logos/logo-ct-inactive-dark.svg?component'
// import LogoSVGCHand from '@/assets/images/logos/logo-chand.svg?component'
// import LogoSVGCHandInvactive from '@/assets/images/logos/logo-chand-inactive-dark.svg?component'
import SvgIcon from '@/components/common/SvgIcon.vue'
import { SPORTS } from '@/utils/variables'

const route = useRoute()
const router = useRouter()

const switchSport = (newSport: string) => {
  if (route.params.sport !== newSport) {
    const updatedPath = route.path.replace(
      new RegExp(`(${Object.values(SPORTS).join('|')})`, 'i'),
      newSport
    )
    router.push(updatedPath)
  }
}
</script>
<style scoped lang="postcss">
.logo {
  @apply h-5 w-[134px] cursor-pointer;
}
</style>
